import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:submit-end', this.handleSubmit);
    // document.addEventListener('turbo:submit-start', this.handleShowLoading);
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmit);
  }

  close() {
    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent clicks
    this.element.closest("turbo-frame").src = undefined;
    this.element.remove();
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close();
    }
  }

  handleShowLoading(e) {
    let elements_to_disable = e.srcElement.closest('turbo-frame').getElementsByClassName('can-show-loading')
    Array.from(elements_to_disable).forEach(element => {
      element.classList.add('is-loading');
      element.setAttribute('disabled', true);
    });
  }

  handleHideShowLoading(e) {
    let elements_to_disable = e.srcElement.closest('turbo-frame').getElementsByClassName('can-show-loading');
    Array.from(elements_to_disable).forEach(element => {
      element.classList.remove('is-loading');
      element.removeAttribute('disabled');
    });
  }

  handleSubmit = (e) => {
    this.handleHideShowLoading(e);
    if (e.detail.success) {
      this.close();
    }
  }
} 

import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js";

export default class extends Controller {
  static targets = ['field', 'selection'];

  connect() {
    console.log('AutoComplete Connected');
    this.ac = new autoComplete({
      selector: `#${this.fieldTarget.id}`,
      placeHolder: "Enter Shareholder Name...",
      data: {
          src: async (query) => {
            try {
              const source = await fetch('/shareholders/search?' + new URLSearchParams({
                term: query,
              }));
              const data = await source.json();
              return data;
            } catch (error) {
              return error;
            }
          },
          keys: ['label'],
      },
      debounce: 500,
      resultItem: {
          highlight: {
              render: true
          },
      },
      resultsList: {
        element: (list, data) => {
            if (!data.results.length) {
                const message = document.createElement("li");
                message.setAttribute("class", "no_result");
                message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
                list.appendChild(message);
            }
        },
        noResults: true,
      }
    });

    this.ac.input.addEventListener("selection", function (event) {
      const selection = event.detail.selection;
      console.log(selection);
      this.ac.input.blur();
      this.ac.input.value = selection.value['label'];
      this.selectionTarget.value = selection.value['id'];
    }.bind(this));
  }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import "../application.css";
import firebase from 'firebase/compat/app';
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import * as firebaseui from 'firebaseui'
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { createPopper } from '@popperjs/core/lib/popper-lite.js';
require("@hotwired/turbo-rails")
require('jquery') 

window.firebase = firebase;
window.RecaptchaVerifier = RecaptchaVerifier;

window.Chart = Chart;
window.ChartDataLabels = ChartDataLabels;
window.Chart.defaults.font.size = 14;

window.createPopper = createPopper;

const firebaseAppConfig = {
  apiKey: process.env.FIREBASE_APIKEY,
  authDomain: process.env.FIREBASE_AUTHDOMAIN,
  projectId: process.env.FIREBASE_PROJECTID,
  storageBucket: process.env.FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
  appId: process.env.FIREBASE_APPID,
  measurementId: process.env.FIREBASE_MEASUREMENTID
}

firebase.initializeApp(firebaseAppConfig);
window.auth = getAuth();

Rails.start()
ActiveStorage.start()
